#root {
    height: 100%;
    width: 100%;
    max-width: 480px;
    overflow: hidden;
}

body
{
  overscroll-behavior-y: contain;
}


input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
